import * as tslib_1 from "tslib";
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from 'src/_util/events';
var AdService = /** @class */ (function () {
    function AdService(socketService) {
        this.socketService = socketService;
        this.ads = [];
    }
    AdService.prototype.init = function () {
        var _this = this;
        return this.socketService.call(Events.GET_ADS, function (ads) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.ads = ads;
                return [2 /*return*/];
            });
        }); });
    };
    AdService.prototype.createAd = function (options) {
        var _this = this;
        return this.socketService.call(Events.CREATE_AD, function (ad) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.ads.push(ad);
                return [2 /*return*/];
            });
        }); }, options);
    };
    AdService.prototype.editAd = function (adId, options) {
        var _this = this;
        return this.socketService.call(Events.EDIT_AD, function (ad) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingAd;
            return tslib_1.__generator(this, function (_a) {
                existingAd = this.getAdById(adId);
                Object.assign(existingAd, ad);
                return [2 /*return*/];
            });
        }); }, tslib_1.__assign({ adId: adId }, options));
    };
    AdService.prototype.deleteAd = function (adId) {
        var _this = this;
        return this.socketService.call(Events.DELETE_AD, function (ad) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingIndex;
            return tslib_1.__generator(this, function (_a) {
                existingIndex = this.ads.findIndex(function (existingAd) { return existingAd._id === ad._id; });
                if (existingIndex === -1) {
                    return [2 /*return*/];
                }
                this.ads.splice(existingIndex, 1);
                return [2 /*return*/];
            });
        }); }, { adId: adId });
    };
    AdService.prototype.getAdsForCampaign = function (campaignId) {
        return this.socketService.call(Events.GET_ADS_FOR_CAMPAIGN, undefined, { campaignId: campaignId });
    };
    AdService.prototype.getAllAds = function () {
        return this.socketService.call(Events.GET_ALL_ADS, undefined, {});
    };
    AdService.prototype.getAds = function () {
        return this.ads;
    };
    AdService.prototype.getAdById = function (id) {
        return this.ads.find(function (ad) { return ad._id === id; });
    };
    return AdService;
}());
export { AdService };
