import * as tslib_1 from "tslib";
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from 'src/_util/events';
var CampaignService = /** @class */ (function () {
    function CampaignService(socketService) {
        this.socketService = socketService;
        this.campaigns = [];
    }
    CampaignService.prototype.init = function () {
        var _this = this;
        return this.socketService.call(Events.GET_CAMPAIGNS, function (campaigns) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.campaigns = campaigns;
                return [2 /*return*/];
            });
        }); });
    };
    CampaignService.prototype.getImpressionLocationsForCampaign = function (campaignId, options) {
        return this.socketService.call(Events.GET_IMPRESSION_LOCATIONS_FOR_CAMPAIGN, undefined, tslib_1.__assign({ campaignId: campaignId }, options));
    };
    CampaignService.prototype.getImpressionLocations = function (options) {
        return this.socketService.call(Events.GET_IMPRESSION_LOCATIONS_FOR_CAMPAIGN, undefined, tslib_1.__assign({}, options));
    };
    CampaignService.prototype.getImpressionsForCampaign = function (campaignId, options) {
        return this.socketService.call(Events.GET_IMPRESSIONS_FOR_CAMPAIGN, undefined, tslib_1.__assign({ campaignId: campaignId }, options));
    };
    CampaignService.prototype.getImpressionsForNetwork = function (networkId, options) {
        return this.socketService.call(Events.GET_IMPRESSIONS_FOR_CAMPAIGN, undefined, tslib_1.__assign({ networkId: networkId }, options));
    };
    CampaignService.prototype.getCostForCampaign = function (campaignId, options) {
        return this.socketService.call(Events.GET_COST_FOR_CAMPAIGN, undefined, tslib_1.__assign({ campaignId: campaignId }, options));
    };
    CampaignService.prototype.createCampaign = function (options) {
        var _this = this;
        return this.socketService.call(Events.CREATE_CAMPAIGN, function (campaign) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.campaigns.push(campaign);
                return [2 /*return*/];
            });
        }); }, options);
    };
    CampaignService.prototype.editCampaign = function (campaignId, options) {
        var _this = this;
        return this.socketService.call(Events.EDIT_CAMPAIGN, function (campaign) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingCampaign;
            return tslib_1.__generator(this, function (_a) {
                existingCampaign = this.getCampaignById(campaignId);
                Object.assign(existingCampaign, campaign);
                return [2 /*return*/];
            });
        }); }, tslib_1.__assign({ campaignId: campaignId }, options));
    };
    CampaignService.prototype.deleteCampaign = function (campaignId) {
        var _this = this;
        return this.socketService.call(Events.DELETE_CAMPAIGN, function (campaign) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingIndex;
            return tslib_1.__generator(this, function (_a) {
                existingIndex = this.campaigns.findIndex(function (existingCampaign) { return existingCampaign._id === campaign._id; });
                if (existingIndex === -1) {
                    return [2 /*return*/];
                }
                this.campaigns.splice(existingIndex, 1);
                return [2 /*return*/];
            });
        }); }, { campaignId: campaignId });
    };
    CampaignService.prototype.getCampaigns = function () {
        return this.campaigns;
    };
    CampaignService.prototype.getCampaignById = function (id) {
        return this.campaigns.find(function (campaign) { return campaign._id === id; });
    };
    return CampaignService;
}());
export { CampaignService };
