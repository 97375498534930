import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-segment',
	templateUrl: './segment.component.html',
	styleUrls: ['./segment.component.scss'],
})
export class SegmentComponent implements OnInit {
	@Input()
	public header;

	@Input()
	public icon;

	@Input()
	public collapsed: boolean = false;

	@Input()
	public padding: boolean = true;

	@Input()
	public loading: boolean = false;

	@Input()
	public actions: any[] = [];

	@Input()
	public dropdowns: any[] = [];

	constructor() { }

	ngOnInit() {

	}

	public toggleCollapsed(): void {
		this.collapsed = !this.collapsed;
	}
}
