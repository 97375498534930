import { NotificationsService, Options as NotifOpts } from 'angular2-notifications';
import { Injectable } from '@angular/core';

export interface NotifsOverride extends NotifOpts {
  clearAllPreviousNotifs?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
	constructor(private notifications: NotificationsService) {}

  public conditionalClear<T extends NotifsOverride>(override: T){
    if(override && override['clearAllPreviousNotifs'] === true){
      this.clearNotifications();
    }
  }

	public displayError(error: any, override: NotifsOverride = {}): void {
    console.error('cc-trace-9690-d879f7d59c95', error);
    this.conditionalClear(override);
		this.notifications.error('Oops', error, override);
	}

	public displaySuccess(success: string, override: NotifsOverride = {}): void {
    this.conditionalClear(override);
		this.notifications.success('Success', success, override);
	}

  public displayInfo(message: string, override: NotifsOverride = {}): void {
    this.conditionalClear(override);
		this.notifications.info('Info', message, override);
	}

  public clearNotifications(): void {
    this.notifications.remove();
  }
}
