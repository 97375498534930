/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./token.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./token.component";
import * as i3 from "@angular/router";
import * as i4 from "../_services/user.service";
import * as i5 from "../../../../common/frontend/_services/loader.service";
var styles_TokenComponent = [i0.styles];
var RenderType_TokenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TokenComponent, data: {} });
export { RenderType_TokenComponent as RenderType_TokenComponent };
export function View_TokenComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_TokenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-token", [], null, null, null, View_TokenComponent_0, RenderType_TokenComponent)), i1.ɵdid(1, 114688, null, 0, i2.TokenComponent, [i3.Router, i3.ActivatedRoute, i4.UserService, i5.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TokenComponentNgFactory = i1.ɵccf("app-token", i2.TokenComponent, View_TokenComponent_Host_0, {}, {}, []);
export { TokenComponentNgFactory as TokenComponentNgFactory };
