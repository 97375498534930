import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from '../_util/events';
import { NotificationService } from '@citadel/common-frontend/_services/notification.service';

@Injectable()
export class BillingService {
	constructor(private socket: SocketService, private notifications: NotificationService, private loader: LoaderService,) { }

	public init(): Observable<any> {
		return new Observable((observer) => {
			observer.next();
			observer.complete();
		});
	}

	public createStripeAccount(email: string): Observable<any> {
		return this.socket.call(
			Events.CREATE_STRIPE_ACCOUNT,
			null,
			{ email }
		);
	}

	public async openBillingPortal(): Promise<void> {
		try {
			this.loader.setLoading(true, 'Loading...');
			await this.socket.call(
				Events.OPEN_BILLING_PORTAL,
				(data) => {
					this.loader.setLoading(false);
					const link = data.url;
					window.open(link, '_blank');
				},
				{ returnUrl: window.location.origin + window.location.pathname }
			).toPromise();
		} catch (e) {
      console.error("cc-trace-bed3-d36a4f1a30a1", e);
			this.notifications.displayError(e);
			this.loader.setLoading(false);
		}
	}

	public async openConnectOnboardingPortal(): Promise<void> {
		try {
			this.loader.setLoading(true, 'Loading...');
			await this.socket.call(
				Events.OPEN_CONNECT_ONBOARDING_PORTAL,
				(data) => {
					this.loader.setLoading(false);
					const link = data.url;
					window.open(link, '_blank');
				},
				{ returnUrl: window.location.origin + window.location.pathname }
			).toPromise();
		} catch (e) {
      console.error("cc-trace-833d-fc729b8c13bd", e);
			this.notifications.displayError(e);
			this.loader.setLoading(false);
		}
	}

	public async openConnectAccountPortal(): Promise<void> {
		try {
			this.loader.setLoading(true, 'Loading...');
			await this.socket.call(
				Events.OPEN_CONNECT_ACCOUNT_PORTAL,
				(data) => {
					this.loader.setLoading(false);
					const link = data.url;
					window.open(link, '_blank');
				},
				{ returnUrl: window.location.origin + window.location.pathname }
			).toPromise();
		} catch (e) {
      console.error("cc-trace-9533-91aebcfaeec2", e);
			this.notifications.displayError(e);
			this.loader.setLoading(false);
		}
	}
}
