import * as tslib_1 from "tslib";
import { UserService } from 'src/_services/user.service';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import { AnalyticsService } from '@citadel/common-frontend/_services/analytics.service';
import { VersionService } from '../_services/version.service';
var loaded = false;
var UnauthenticatedGuard = /** @class */ (function () {
    function UnauthenticatedGuard(socket, analytics, version, user) {
        this.socket = socket;
        this.analytics = analytics;
        this.version = version;
        this.user = user;
    }
    UnauthenticatedGuard.prototype.canActivate = function (route) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var completion;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (loaded) {
                            resolve(true);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.socket.init(environment.api.url, null).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.analytics.init(environment.analytics.google.id).toPromise()];
                    case 2:
                        _a.sent();
                        completion = Promise.all([this.version.init().toPromise(), this.user.init(false).toPromise()]);
                        completion
                            .then(function () {
                            // hacky but necessary
                            var element = document.getElementById('cdm-preload');
                            if (element) {
                                element.remove();
                            }
                            loaded = true;
                            resolve(true);
                        })
                            .catch(function (err) {
                            reject(err);
                            resolve(false);
                        });
                        return [2 /*return*/];
                }
            });
        }); });
        return promise;
    };
    return UnauthenticatedGuard;
}());
export { UnauthenticatedGuard };
export function markForChange() {
    loaded = false;
}
