import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';

import { UserService } from '../_services/user.service';

@Component({
	selector: 'app-token',
	templateUrl: './token.component.html',
	styleUrls: ['./token.component.scss'],
})
export class TokenComponent implements OnInit {
	constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private loaderService: LoaderService) { }

	ngOnInit() {
		this.loaderService.setLoading(true);

		this.activatedRoute.queryParams.subscribe((queryParams) => {
			const sessionString = queryParams['session'];
			if (!sessionString) {
				this.loaderService.setLoading(false);
				this.userService.logout();
				return;
			}

			this.userService.loginWithSession(sessionString);
			this.router.navigate(['/dashboard']);
		});
	}
}
