import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import IPermission from '../_interfaces/IPermission';
import IEntitlement from '../_interfaces/IEntitlement';
import IDatabaseObject from '../_interfaces/IDatabaseObject';
import Events from '../_util/events';
import { SocketService } from './socket.service';
import { AccessLevel } from '../_enum/IAccessLevel';
import { EntitlementResourceType } from '../_enum/IEntitlementResourceType';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface IEvaluatedAccessLevel {
	resourceId: string;
	resourceType: EntitlementResourceType;
	accessLevel: AccessLevel;
	entitlement?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
	private myPermissions: IPermission[] = [];
	private permissions: IPermission[] = [];
	private entitlements: IEntitlement[] = [];

	constructor(private socketService: SocketService) {}

	public init(): Observable<any> {
		return new Observable<any>((observer) => {
			// nested observables - slightly hacky
			this.socketService
				.call(Events.GET_PERMISSIONS, (myPermissions) => {
					this.myPermissions = myPermissions;
				})
				.subscribe((_) => {
					this.socketService
						.call(Events.LIST_PERMISSION_TYPES, (permissions) => {
							this.permissions = permissions;
						})
						.subscribe((__) => {
							this.socketService
								.call(Events.LIST_ENTITLEMENTS, (entitlements) => {
									this.entitlements = entitlements;
								}).subscribe((_) => {
									observer.next();
									observer.complete();
								});
						});
				});
		});
	}

  public isFirstTimeUser(user: any) {
    // Fetch payment methods asynchronously
    return this.socketService.call(Events.LIST_ENTITLEMENTS, undefined, user).pipe(
      map((paymentMethods: any[]) => {
        return paymentMethods && paymentMethods.length === 0; // First-time user if no payment methods
      }),
      catchError((error) => {
        console.error('Error fetching payment methods:', error);
        return of(false);
      })
    );
  }

  public isFirstTimeUserToPromise(user: any): Promise<boolean> {
    return this.isFirstTimeUser(user).toPromise(); // Convert the observable to a promise
  }

	public getMyPermissions(): IPermission[] {
		return this.myPermissions;
	}

	public getPermissions(): IPermission[] {
		return this.permissions;
	}

	public getEntitlements(): IEntitlement[] {
		return this.entitlements;
	}

	public getEntitlementsForResource(resource: IDatabaseObject): IEntitlement[] {
		return this.entitlements.filter(entitlement => entitlement.target.resource === resource._id);
	}

	public getEntitlementsForUser(userId: string): IEntitlement[] {
		return this.entitlements.filter(entitlement => entitlement.user === userId);
	}

	public getEvaluatedEntitlementsForUser(userId: string): Observable<any> {
		return this.socketService
		.call(Events.GET_EVALUATED_ENTITLEMENT_ACCESS, undefined, userId);
	}

	public createEntitlement(resource: IDatabaseObject, resourceType: EntitlementResourceType, userId: string, accessLevel: AccessLevel): Observable<IEntitlement> {
		return this.socketService
			.call(Events.CREATE_ENTITLEMENT, (entitlement) => {
				this.entitlements.push(entitlement);
			}, resource, resourceType, userId, accessLevel)
	}


	public editEntitlement(entitlement: IEntitlement, config: any): Observable<IEntitlement> {
		return this.socketService
			.call(Events.EDIT_ENTITLEMENT, (editedEntitlement) => {
				Object.assign(entitlement, editedEntitlement);
			}, entitlement._id, config)
	}

	public deleteEntitlement(entitlement: IEntitlement): Observable<IEntitlement> {
		return this.socketService
			.call(Events.DELETE_ENTITLEMENT, (entitlement) => {
				this.entitlements = this.entitlements.filter((innerEntitlement) => innerEntitlement._id !== entitlement._id);
			}, entitlement._id)
	}

	public hasAccessLevelOnResource(resource: IDatabaseObject, accessLevel: AccessLevel): boolean {
		if (!resource['@permission']) {
			return undefined;
		}

		const currentAccessLevel = resource['@permission'] as AccessLevel;

		switch (accessLevel) {
			case AccessLevel.OWNER:
				return currentAccessLevel === AccessLevel.OWNER;
			case AccessLevel.EDITOR:
				return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR;
			case AccessLevel.VIEWER:
				return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR || currentAccessLevel === AccessLevel.VIEWER;
		}

		return false;
	}

	public isAdmin(userType: string): boolean {
		return (userType === 'admin' || userType === 'internal');
	}

	public checkForPermission(userType: string, permissionType: string): boolean {
		if (this.isAdmin(userType)) {
			return true;
		}
		return !!this.myPermissions.find((permission) => permission.type === permissionType);
	}

	public getPermissionById(id: string): IPermission {
		return this.permissions.find((permission) => permission._id === id);
	}
}
