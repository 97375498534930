import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

import ISelectSearchModalContext from './selectSearch.interface';
import { SelectSearchModalComponent } from './selectSearch.modal';

export class SelectSearchModal extends ComponentModalConfig<ISelectSearchModalContext, void, void> {
	constructor(type: string, options: any[], previousSelected: any, property: string, label: string, size = ModalSize.Normal) {
		super(SelectSearchModalComponent, { type, options, previousSelected, search: { property, label } });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
	}
}
