import * as tslib_1 from "tslib";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from '../_util/events';
import { environment } from '../environments/environment';
export var SESSION_ID = 'ads_session';
var UserService = /** @class */ (function () {
    function UserService(router, socketService, cookies) {
        this.router = router;
        this.socketService = socketService;
        this.cookies = cookies;
    }
    UserService.prototype.init = function (authenticated) {
        var _this = this;
        if (authenticated) {
            var cookie = this.getSessionCookie();
            if (!cookie) {
                throw new Error('invalid session');
            }
            this.session = JSON.parse(atob(cookie));
            this.socketService.getSocketConnection().on('reconnect', function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.sendToken().toPromise()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
            return this.sendToken();
        }
        return new Observable(function (observer) { return observer.complete(); });
    };
    UserService.prototype.sendToken = function () {
        var _this = this;
        return this.socketService.call(Events.AUTHENTICATE_TOKEN_EVENT, function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = user;
                return [2 /*return*/];
            });
        }); }, this.session.token);
    };
    UserService.prototype.login = function (config) {
        var _this = this;
        return this.socketService.call(Events.LOGIN, function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var session;
            return tslib_1.__generator(this, function (_a) {
                session = response.data;
                this.user = session.user;
                this.setSession(session);
                this.router.navigate(['/dashboard']);
                return [2 /*return*/];
            });
        }); }, config);
    };
    UserService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.clearSession();
                setTimeout(function () {
                    _this.router.navigate(['/authentication/login']);
                }, 100);
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.getUser = function () {
        return this.user;
    };
    UserService.prototype.setSession = function (session) {
        this.session = session;
        // tslint:disable-next-line:max-line-length
        this.cookies.set(SESSION_ID, btoa(JSON.stringify(session)), new Date(session.expires), '/', environment.cookie.url, environment.cookie.secure, environment.cookie.sameSite);
    };
    UserService.prototype.loginWithSession = function (sessionString) {
        if (!sessionString) {
            throw new Error('invalid session');
        }
        var session = JSON.parse(atob(sessionString));
        this.user = session.user;
        this.setSession(session);
        this.router.navigate(['/dashboard']);
    };
    UserService.prototype.clearSession = function () {
        this.user = null;
        this.session = null;
        this.cookies.deleteAll('/', environment.cookie.url);
    };
    UserService.prototype.getSession = function () {
        return this.session;
    };
    UserService.prototype.getSessionCookie = function () {
        return this.cookies.get(SESSION_ID);
    };
    return UserService;
}());
export { UserService };
