import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from 'src/_util/events';
import INetwork from 'src/_interfaces/INetwork';
import IScreen from 'src/_interfaces/IScreen';

@Injectable()
export class NetworkService {
    private networks: INetwork[] = [];

	constructor(private socketService: SocketService) { }

	public init(): Observable<any> {
		return this.socketService.call(
			Events.GET_ALL_NETWORKS,
			async (networks: INetwork[]) => {
				this.networks = networks;
			}
		);
	}

	public getMyNetwork(): Observable<INetwork> {
		return this.socketService.call(
			Events.GET_MY_NETWORK,
			undefined,
			{}
		);
	}

	public editNetworkScreen(screenId, options: { enabled: boolean, classification: any, notes: string }): Observable<IScreen[]> {
		return this.socketService.call(
			Events.EDIT_SCREEN,
			undefined,
			{ screenId, ...options }
		);
	}

	public getScreensFromNetwork(networkId: string): Observable<IScreen[]> {
		return this.socketService.call(
			Events.GET_SCREENS_FROM_NETWORK,
			undefined,
			{ networkId }
		);
	}

	public approveAdForNetwork(networkId: string, options: { adId: string }): Observable<INetwork> {
		return this.socketService.call(
			Events.APPROVE_AD_FOR_NETWORK,
			async (network: INetwork) => {
				const existingNetwork = this.getNetworkById(networkId);
				Object.assign(existingNetwork, network);
			},
			{ networkId, ...options }
		);
	}

	public rejectAdForNetwork(networkId: string, options: { adId: string }): Observable<INetwork> {
		return this.socketService.call(
			Events.REJECT_AD_FOR_NETWORK,
			async (network: INetwork) => {
				const existingNetwork = this.getNetworkById(networkId);
				Object.assign(existingNetwork, network);
			},
			{ networkId, ...options }
		);
	}

	public editNetwork(networkId: string, options: { }): Observable<INetwork> {
		return this.socketService.call(
			Events.EDIT_NETWORK,
			async (network: INetwork) => {
				const existingNetwork = this.getNetworkById(networkId);
				Object.assign(existingNetwork, network);
			},
			{ networkId, ...options }
		);
	}

	public getNetworks(): INetwork[] {
		return this.networks;
	}

    public getNetworkById(id: string): INetwork {
        return this.networks.find(network => network._id === id);
    }
}
