import { SuiModal } from 'ng2-semantic-ui';

import { Component, OnInit, ViewChild } from '@angular/core';

import ISelectSearchModalContext from './selectSearch.interface';
import { TableComponent } from '../../_components/table/table.component';

@Component({
	selector: 'app-modal-select-search',
	templateUrl: './selectSearch.modal.html',
	styleUrls: ['./selectSearch.modal.scss'],
})
export class SelectSearchModalComponent implements OnInit {
	@ViewChild('table')
	public table: TableComponent;

	public previousSelected: any;
	public type: string;
	public options: any[];
	public search: any;
	public columns: any[] = [];

	constructor(public modal: SuiModal<ISelectSearchModalContext, void, void>) {
		this.type = this.modal.context.type;
		this.previousSelected = this.modal.context.previousSelected;
		this.options = this.modal.context.options;
		this.search = this.modal.context.search;

		this.columns = [{ label: this.search.label, field: this.search.property, searchable: true }];
	}

	ngOnInit() {
		if (this.table) {
			this.table.refresh();
		}
	}

	public chooseResource(option: any) {
		this.modal.approve(option);
	}

	public hasProperty(property: string) {
		if (this.options.length > 0) {
			return !!this.options[0][property];
		}
		return false;
	}
}
