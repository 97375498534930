import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input()
	public header;

	@Input()
	public subHeader;

	@Input()
	public sideHeader;

	@Input()
	public actions: any[] = [];

	@Input()
	public showBack = false;

	constructor(private title: Title, private location: Location, private router: Router) { }

	ngOnInit() {
		this.title.setTitle(`Citadel Ads — ${this.header}`);
	}

	public goBack(): void {
		this.location.back();
	}
}
