export const environment = {
	production: false,
	api: {
		url: `https://api.dev.ads.citadelcenter.net`,
		version: '1.0.0',
	},
	cookie: {
		url: window.location.host,
		secure: true,
		sameSite: 'None',
	},
	keys: {
		google: {
			maps: 'AIzaSyA9_Z_VHJacWmrm2-Ootw-BzmMB7z-u8rM',
		},
		stripe: {
			public: 'pk_test_51OtXtqKCjt4hsWsvw9mE4h2c7iBR6eaxE7MtVaruhdIu1meUkyXwuJQ2Gllf7jEcift3Dh9DTLMCWmkn1kg9LUlU00ooPhpyQF',
		},
	},
	analytics: {
		google: {
			id: null
		}
	}
};
