import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from '../_util/events';
import { NotificationService } from '@citadel/common-frontend/_services/notification.service';
var BillingService = /** @class */ (function () {
    function BillingService(socket, notifications, loader) {
        this.socket = socket;
        this.notifications = notifications;
        this.loader = loader;
    }
    BillingService.prototype.init = function () {
        return new Observable(function (observer) {
            observer.next();
            observer.complete();
        });
    };
    BillingService.prototype.createStripeAccount = function (email) {
        return this.socket.call(Events.CREATE_STRIPE_ACCOUNT, null, { email: email });
    };
    BillingService.prototype.openBillingPortal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loader.setLoading(true, 'Loading...');
                        return [4 /*yield*/, this.socket.call(Events.OPEN_BILLING_PORTAL, function (data) {
                                _this.loader.setLoading(false);
                                var link = data.url;
                                window.open(link, '_blank');
                            }, { returnUrl: window.location.origin + window.location.pathname }).toPromise()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error("cc-trace-bed3-d36a4f1a30a1", e_1);
                        this.notifications.displayError(e_1);
                        this.loader.setLoading(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BillingService.prototype.openConnectOnboardingPortal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loader.setLoading(true, 'Loading...');
                        return [4 /*yield*/, this.socket.call(Events.OPEN_CONNECT_ONBOARDING_PORTAL, function (data) {
                                _this.loader.setLoading(false);
                                var link = data.url;
                                window.open(link, '_blank');
                            }, { returnUrl: window.location.origin + window.location.pathname }).toPromise()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error("cc-trace-833d-fc729b8c13bd", e_2);
                        this.notifications.displayError(e_2);
                        this.loader.setLoading(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BillingService.prototype.openConnectAccountPortal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loader.setLoading(true, 'Loading...');
                        return [4 /*yield*/, this.socket.call(Events.OPEN_CONNECT_ACCOUNT_PORTAL, function (data) {
                                _this.loader.setLoading(false);
                                var link = data.url;
                                window.open(link, '_blank');
                            }, { returnUrl: window.location.origin + window.location.pathname }).toPromise()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        console.error("cc-trace-9533-91aebcfaeec2", e_3);
                        this.notifications.displayError(e_3);
                        this.loader.setLoading(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return BillingService;
}());
export { BillingService };
