import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import ICampaign from 'src/_interfaces/ICampaign';
import Events from 'src/_util/events';
import IAd from 'src/_interfaces/IAd';
import INetworkGroup from 'src/_interfaces/INetworkGroup';

@Injectable()
export class NetworkGroupService {
	private networkGroups: INetworkGroup[] = [];

	constructor(private socketService: SocketService) { }

	public init(): Observable<any> {
		return this.socketService.call(
			Events.GET_ALL_NETWORK_GROUPS,
			async (networkGroups: INetworkGroup[]) => {
				this.networkGroups = networkGroups;
			}
		);
	}

	public createNetworkGroup(networkId: string, options: { name: string }): Observable<INetworkGroup> {
		return this.socketService.call(
			Events.CREATE_NETWORK_GROUP,
			async (networkGroup: INetworkGroup) => {
				this.networkGroups.push(networkGroup);
			},
			{ networkId, ...options }
		);
	}

	public editNetworkGroup(networkGroupId: string, options: { name: string }): Observable<IAd> {
		return this.socketService.call(
			Events.EDIT_NETWORK_GROUP,
			async (networkGroup: INetworkGroup) => {
				const existingNetworkGroup = this.getNetworkGroupById(networkGroupId);
				Object.assign(existingNetworkGroup, networkGroup);
			},
			{ networkGroupId, ...options }
		);
	}

	public deleteNetworkGroup(networkGroupId: string): Observable<ICampaign> {
		return this.socketService.call(
			Events.DELETE_NETWORK_GROUP,
			async (networkGroup: INetworkGroup) => {
				const existingIndex = this.networkGroups.findIndex(existingNetworkGroup => existingNetworkGroup._id === networkGroup._id);

				if (existingIndex === -1) {
					return;
				}

				this.networkGroups.splice(existingIndex, 1);
			},
			{ networkGroupId }
		);
	}

	public getNetworkGroupsForNetwork(networkId: string): Observable<INetworkGroup[]> {
		return this.socketService.call(
			Events.GET_NETWORK_GROUPS_FOR_NETWORK,
			undefined,
			{ networkId }
		);
	}

	public getNetworkGroups(): INetworkGroup[] {
		return this.networkGroups;
	}

    public getNetworkGroupById(id: string): INetworkGroup {
        return this.networkGroups.find(networkGroup => networkGroup._id === id);
    }
}
