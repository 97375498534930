<div class="cdm-scheduled-slot" [formGroup]="scheduledSlot" *ngIf="scheduledSlot">
	<div *ngIf="showFrequency" class="cdm-scheduled-slot__section ui segment" formGroupName="frequency">
		<div class="ui form">
			<h4 class="ui dividing header cdm-scheduled-slot__header">
				<span>Frequency Scheduling</span>
			</h4>
		</div>

		<span>Use this setting to configure the frequency at which to play this content</span>

		<div class="cdm-scheduled-slot__content">
			<form class="ui form" ngNoForm>
				<div class="grouped fields">
					<label>Playback scheduling method (must select one)</label>

					<div class="field">
						<sui-radio-button class="toggle" name="type" formControlName="type" class="toggle"
							value="standard">Play regularly during scheduled window</sui-radio-button>
					</div>

					<div class="field">
						<sui-radio-button class="toggle" name="type" formControlName="type" class="toggle"
							value="override">Always play when conditions are met (overrides other
							content)</sui-radio-button>
					</div>

					<div class="field">
						<sui-radio-button class="toggle" name="type" formControlName="type" class="toggle"
							value="interval">Play on specified interval during scheduled window</sui-radio-button>
					</div>
				</div>
			</form>

			<div class="ui form cdm-scheduled-slot__frequency"
				*ngIf="scheduledSlot.get('frequency.type').value === 'interval'" formGroupName="interval">
				<div class="field">
					<label>Playback frequency interval</label>

					<div class="ui labeled action input">
						<div class="ui label">Every</div>
						<input type="number" formControlName="value" />
						<sui-select class="selection" formControlName="period" [options]="frequencySelectionOptions"
							placeholder="Choose" [isSearchable]="false" [isDisabled]="false" labelField="label"
							valueField="value" #select>
							<sui-select-option *ngFor="let option of select.filteredOptions" [value]="option">
							</sui-select-option>
						</sui-select>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="cdm-scheduled-slot__section ui segment" formGroupName="weekday">
		<div class="ui form">
			<h4 class="ui dividing header cdm-scheduled-slot__header">
				<span>Weekday Scheduling</span>
				<div class="cdm-scheduled-slot__checkbox">
					<sui-checkbox formControlName="enabled" class="toggle"></sui-checkbox>
				</div>
			</h4>
		</div>

		<span>Use this setting to configure specific weekdays to play this content on</span>

		<div class="cdm-scheduled-slot__content" formGroupName="selected"
			[ngClass]="{ 'cdm-scheduled-slot__content--disabled': !scheduledSlot.get('weekday.enabled').value }">
			<ng-container *ngFor="let day of days">
				<div class="field">
					<div class="ui cdm-scheduled-slot__weekday-selection">
						<sui-checkbox formControlName="{{ day.toLowerCase() }}" class="toggle">Play content on <b>{{ day
								}}</b></sui-checkbox>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="cdm-scheduled-slot__section ui segment" formGroupName="dates">
		<div class="ui form">
			<h4 class="ui dividing header cdm-scheduled-slot__header">
				<span>Date Range Scheduling</span>
				<div class="cdm-scheduled-slot__checkbox">
					<sui-checkbox formControlName="enabled" class="toggle"></sui-checkbox>
				</div>
			</h4>
		</div>

		<span>Use this setting to configure specific dates to play this content on</span>

		<div class="cdm-scheduled-slot__content"
			[ngClass]="{ 'cdm-scheduled-slot__content--disabled': !scheduledSlot.get('dates.enabled').value }">
			<div class="ui form" formGroupName="range">
				<div class="two fields">
					<div class="field">
						<label>Start date</label>
						<div class="ui left icon input">
							<i class="calendar icon"></i>
							<input suiDatepicker formControlName="start" [pickerMode]="'date'"
								[pickerFirstDayOfWeek]="1" />
						</div>
					</div>
					<div class="field">
						<label>End date</label>
						<div class="ui left icon input">
							<i class="calendar icon"></i>
							<input suiDatepicker formControlName="end" [pickerMode]="'date'"
								[pickerFirstDayOfWeek]="1" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="cdm-scheduled-slot__section ui segment" formGroupName="times">
		<div class="ui form">
			<h4 class="ui dividing header cdm-scheduled-slot__header">
				<span>Time Scheduling</span>
				<div class="cdm-scheduled-slot__checkbox">
					<sui-checkbox formControlName="enabled" class="toggle"></sui-checkbox>
				</div>
			</h4>
		</div>

		<span>Use this setting to configure specific times to play this content on</span>

		<div class="cdm-scheduled-slot__content"
			[ngClass]="{ 'cdm-scheduled-slot__content--disabled': !scheduledSlot.get('times.enabled').value }">
			<div formArrayName="range">
				<div class="ui form" *ngFor="let r of scheduledSlot.get('times.range')['controls']; let i = index"
					[formGroupName]="i">
					<div class="two fields">
						<div class="field">
							<label>Start time {{ i + 1 }}</label>
							<div class="ui left icon input">
								<i class="clock icon"></i>
								<input suiDatepicker formControlName="start" [pickerMode]="'time'" />
							</div>
						</div>
						<div class="field">
							<label>End time {{ i + 1 }}</label>
							<div class="ui left icon input">
								<i class="clock icon"></i>
								<input suiDatepicker formControlName="end" [pickerMode]="'time'" />
							</div>
						</div>
						<div class="cdm-scheduled-slot__time-button-container">
							<button type="button" class="cdm-scheduled-slot__time-button ui right floated compact icon button"
								(click)="deleteTime(i)">
								<i class="trash icon"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<button type="button" class="ui button" (click)="addTimeRange()">Add Time Schedule</button>
		</div>
	</div>
</div>