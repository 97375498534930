import { ModalService } from '@citadel/common-frontend/_services/modal.service';
import { SelectSearchModal } from '../../_modals/selectSearch/selectSearch.export';

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-select-search',
	templateUrl: './select-search.component.html',
	styleUrls: ['./select-search.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectSearchComponent),
			multi: true,
		},
	],
})
export class SelectSearchComponent implements ControlValueAccessor {
	@Input('value')
	selected: any;
	@Input()
	type = 'resource';
	@Input()
	options: any[];
	@Input()
	isDisabled = false;

	@Input()
	public compact: boolean = false;

	onChange: any = () => { };
	onTouched: any = () => { };

	constructor(private modal: ModalService) { }

	public openResourceSelectModal($event) {
		$event.stopPropagation();

		if (this.isDisabled) {
			return;
		}

		this.modal
			.open(new SelectSearchModal(this.type, this.options, this.selected, 'name', 'Name'))
			.onApprove((object) => {
				this.writeValue(object);
			})
			.onDeny(() => { });
	}

	get value() {
		return this.selected;
	}

	set value(val) {
		this.selected = val;
		this.onChange(val);
		this.onTouched();
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	writeValue(value) {
		this.value = value;
	}
}
