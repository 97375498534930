<div id="{{ tableId }}" class="cdm-table"
	[ngClass]="{ 'cdm-table--empty': visibleItems.length === 0, 'cdm-table--selectable': onRowSelect.observers.length > 0 }">
	<table class="ui single line table" [ngClass]="{ selectable: onRowSelect.observers.length > 0 }">
		<thead>
			<tr class="cdm-table__header">
				<th *ngIf="onCheckChange.observers.length > 0"></th>
				<th class="collapsing" *ngIf="showId">ID</th>
				<th *ngFor="let column of columns">{{ column.label }}</th>
				<ng-container *ngIf="getHeaderFillerColumnCount() > 0">
					<th class="one wide" *ngFor="let item of [].constructor(getHeaderFillerColumnCount())"></th>
				</ng-container>
				<th class="one wide" *ngIf="getHeaderFillerColumnCount() > 0">
					<div class="ui basic right pointing dropdown compact button cdm-table__menu" suiDropdown>
						<i class="bars icon"></i>
						<div class="menu" suiDropdownMenu>
							<div class="item" (click)="toggleIDs()">Toggle IDs</div>
						</div>
					</div>
				</th>
			</tr>
			<tr class="cdm-table__search">
				<th *ngIf="onCheckChange.observers.length > 0"></th>
				<th *ngIf="showId">
					<div>
						<div class="ui input mini">
							<input type="text" placeholder="Search..." [(ngModel)]="filter.searchToken"
								(ngModelChange)="onSearchChange()" />
						</div>
					</div>
				</th>
				<th *ngFor="let column of columns">
					<ng-container *ngIf="column.searchable">
						<div>
							<div class="ui input mini">
								<input type="text" placeholder="Search..." [(ngModel)]="filter[column.field]"
									(ngModelChange)="onSearchChange()" />
							</div>
						</div>
					</ng-container>
				</th>
				<th *ngIf="onDelete.observers.length + onView.observers.length > 1"></th>
				<th *ngFor="let action of actions"></th>
				<th *ngIf="onDelete.observers.length > 0 || onView.observers.length > 0">
					<button class="ui button basic fluid compact" (click)="clearFilter()">Clear</button>
				</th>
			</tr>
		</thead>
		<div class="ui placeholder segment cdm-table__empty" *ngIf="visibleItems.length === 0">
			<div class="ui icon header">
				<i class="search icon"></i>
				{{ items.length === 0 ? 'No resources found' : 'No resources found with the provided filter' }}
			</div>
		</div>
		<tbody *ngIf="visibleItems.length !== 0">
			<tr *ngFor="let item of visibleItems" (click)="onItemClicked(item)" [ngClass]="getRowClasses(item)">
				<td *ngIf="onCheckChange.observers.length > 0" class="collapsing cdm-table__checkbox-td"
					(click)="$event.stopPropagation()">
					<div>
						<sui-checkbox class="toggle" [ngModel]="isInternalItemSelected(item)"
							(checkChange)="onInternalItemCheckChange($event, item)"> </sui-checkbox>
					</div>
				</td>

				<td class="cdm-table__shared" *ngIf="showId">
					<b>{{ item.searchToken }}</b>
					<a class="ui basic blue label tiny horizontal" *ngIf="isSharedResource(item)">Shared with me</a>
				</td>
				<td *ngFor="let column of columns" [ngClass]="{ 'cdm-table__allow-overflow': column.type === 'select' }">
					<ng-container *ngIf="!column.type">
						<ng-container *ngIf="column.ui">
							<div class="ui label {{ column.ui.color(item) }}">
								<i *ngIf="column.ui.icon" class="{{ column.ui.icon(item) }} icon"></i>
								<ng-container *ngIf="column.mapper">
									{{ sanitizeCellValue(column.mapper(item[column.field], item)) }}
								</ng-container>
								<ng-container *ngIf="!column.mapper">
									{{ sanitizeCellValue(item[column.field]) }}
								</ng-container>
							</div>
						</ng-container>
						<ng-container *ngIf="!column.ui">
							<ng-container *ngIf="column.mapper">
								{{ sanitizeCellValue(column.mapper(item[column.field], item)) }}
							</ng-container>
							<ng-container *ngIf="!column.mapper">
								{{ sanitizeCellValue(item[column.field]) }}
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="column.type === 'select'">
						<sui-select class="cdm-table__select"
							[ngModel]="column.mapper(item[column.field], item)"
							[options]="column.select.options"
							[placeholder]="column.select.placeholder"
							[labelField]="column.select.labelField"
							[valueField]="column.select.valueField"
							(ngModelChange)="column.select.onChange ? column.select.onChange(item, $event) : null"
							[isSearchable]="true"
							#select>
							<sui-select-option *ngFor="let option of select.filteredOptions"
								[value]="option">
							</sui-select-option>
						</sui-select>
					</ng-container>
					<ng-container *ngIf="column.type === 'toggle'">
						<sui-checkbox 
							class="toggle cdm-table__toggle"
							[ngModel]="item[column.field]"
							(ngModelChange)="column.toggle.onChange ? column.toggle.onChange(item, $event) : null"
						>
						</sui-checkbox>
					</ng-container>
				</td>
				<td class="selectable" *ngIf="!isSharedResource(item) && onView.observers.length > 0">
					<a (click)="$event.stopPropagation(); viewItem(item)"><i class="eye icon"></i></a>
				</td>
				<td class="selectable" *ngFor="let action of actions">
					<a (click)="$event.stopPropagation(); action.onClicked(item)"><i
							class="{{action.icon}} icon"></i></a>
				</td>
				<td class="selectable" *ngIf="!isSharedResource(item) && onDelete.observers.length > 0">
					<a (click)="$event.stopPropagation(); deleteItem(item)"><i class="trash icon"></i></a>
				</td>
			</tr>
		</tbody>
		<tfoot *ngIf="visibleItems.length !== 0">
			<tr>
				<th [attr.colspan]="getEffectiveColumnCount()">
					<div class="ui right floated pagination menu cdm-fs__pagination">
						<a class="icon item" (click)="previousPage()"
							[ngClass]="{ disabled: page === 0 || getMaxPageCount() === 0 }">
							<i class="left chevron icon"></i>
						</a>
						<a class="item" (click)="onBackEllipsis()" *ngIf="showNavigateBackEllipsis">...</a>
						<a class="item" (click)="viewPage(visiblePage)" *ngFor="let visiblePage of visiblePages"
							[ngClass]="{ active: visiblePage === page }">{{ visiblePage + 1 }}</a>
						<a class="item" (click)="onForwardEllipsis()" *ngIf="showNavigateForwardEllipsis">...</a>
						<a class="icon item" (click)="nextPage()"
							[ngClass]="{ disabled: page + 1 === getMaxPageCount() || getMaxPageCount() === 0 }">
							<i class="right chevron icon"></i>
						</a>
					</div>
				</th>
			</tr>
		</tfoot>
	</table>
	<p class="cdm-missing-resource" *ngIf="visibleItems.length === 0 && onRowSelect.observers.length > 0">No resources
		available</p>
</div>