import { Observable } from 'rxjs';
import Events from '../_util/events';
import { SocketService } from './socket.service';
import { AccessLevel } from '../_enum/IAccessLevel';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./socket.service";
var PermissionService = /** @class */ (function () {
    function PermissionService(socketService) {
        this.socketService = socketService;
        this.myPermissions = [];
        this.permissions = [];
        this.entitlements = [];
    }
    PermissionService.prototype.init = function () {
        var _this = this;
        return new Observable(function (observer) {
            // nested observables - slightly hacky
            _this.socketService
                .call(Events.GET_PERMISSIONS, function (myPermissions) {
                _this.myPermissions = myPermissions;
            })
                .subscribe(function (_) {
                _this.socketService
                    .call(Events.LIST_PERMISSION_TYPES, function (permissions) {
                    _this.permissions = permissions;
                })
                    .subscribe(function (__) {
                    _this.socketService
                        .call(Events.LIST_ENTITLEMENTS, function (entitlements) {
                        _this.entitlements = entitlements;
                    }).subscribe(function (_) {
                        observer.next();
                        observer.complete();
                    });
                });
            });
        });
    };
    PermissionService.prototype.isFirstTimeUser = function (user) {
        // Fetch payment methods asynchronously
        return this.socketService.call(Events.LIST_ENTITLEMENTS, undefined, user).pipe(map(function (paymentMethods) {
            return paymentMethods && paymentMethods.length === 0; // First-time user if no payment methods
        }), catchError(function (error) {
            console.error('Error fetching payment methods:', error);
            return of(false);
        }));
    };
    PermissionService.prototype.isFirstTimeUserToPromise = function (user) {
        return this.isFirstTimeUser(user).toPromise(); // Convert the observable to a promise
    };
    PermissionService.prototype.getMyPermissions = function () {
        return this.myPermissions;
    };
    PermissionService.prototype.getPermissions = function () {
        return this.permissions;
    };
    PermissionService.prototype.getEntitlements = function () {
        return this.entitlements;
    };
    PermissionService.prototype.getEntitlementsForResource = function (resource) {
        return this.entitlements.filter(function (entitlement) { return entitlement.target.resource === resource._id; });
    };
    PermissionService.prototype.getEntitlementsForUser = function (userId) {
        return this.entitlements.filter(function (entitlement) { return entitlement.user === userId; });
    };
    PermissionService.prototype.getEvaluatedEntitlementsForUser = function (userId) {
        return this.socketService
            .call(Events.GET_EVALUATED_ENTITLEMENT_ACCESS, undefined, userId);
    };
    PermissionService.prototype.createEntitlement = function (resource, resourceType, userId, accessLevel) {
        var _this = this;
        return this.socketService
            .call(Events.CREATE_ENTITLEMENT, function (entitlement) {
            _this.entitlements.push(entitlement);
        }, resource, resourceType, userId, accessLevel);
    };
    PermissionService.prototype.editEntitlement = function (entitlement, config) {
        return this.socketService
            .call(Events.EDIT_ENTITLEMENT, function (editedEntitlement) {
            Object.assign(entitlement, editedEntitlement);
        }, entitlement._id, config);
    };
    PermissionService.prototype.deleteEntitlement = function (entitlement) {
        var _this = this;
        return this.socketService
            .call(Events.DELETE_ENTITLEMENT, function (entitlement) {
            _this.entitlements = _this.entitlements.filter(function (innerEntitlement) { return innerEntitlement._id !== entitlement._id; });
        }, entitlement._id);
    };
    PermissionService.prototype.hasAccessLevelOnResource = function (resource, accessLevel) {
        if (!resource['@permission']) {
            return undefined;
        }
        var currentAccessLevel = resource['@permission'];
        switch (accessLevel) {
            case AccessLevel.OWNER:
                return currentAccessLevel === AccessLevel.OWNER;
            case AccessLevel.EDITOR:
                return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR;
            case AccessLevel.VIEWER:
                return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR || currentAccessLevel === AccessLevel.VIEWER;
        }
        return false;
    };
    PermissionService.prototype.isAdmin = function (userType) {
        return (userType === 'admin' || userType === 'internal');
    };
    PermissionService.prototype.checkForPermission = function (userType, permissionType) {
        if (this.isAdmin(userType)) {
            return true;
        }
        return !!this.myPermissions.find(function (permission) { return permission.type === permissionType; });
    };
    PermissionService.prototype.getPermissionById = function (id) {
        return this.permissions.find(function (permission) { return permission._id === id; });
    };
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.SocketService)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };
