import { ModalService } from '@citadel/common-frontend/_services/modal.service';
import { SelectSearchModal } from '../../_modals/selectSearch/selectSearch.export';
import { ControlValueAccessor } from '@angular/forms';
var SelectSearchComponent = /** @class */ (function () {
    function SelectSearchComponent(modal) {
        this.modal = modal;
        this.type = 'resource';
        this.isDisabled = false;
        this.compact = false;
        this.onChange = function () { };
        this.onTouched = function () { };
    }
    SelectSearchComponent.prototype.openResourceSelectModal = function ($event) {
        var _this = this;
        $event.stopPropagation();
        if (this.isDisabled) {
            return;
        }
        this.modal
            .open(new SelectSearchModal(this.type, this.options, this.selected, 'name', 'Name'))
            .onApprove(function (object) {
            _this.writeValue(object);
        })
            .onDeny(function () { });
    };
    Object.defineProperty(SelectSearchComponent.prototype, "value", {
        get: function () {
            return this.selected;
        },
        set: function (val) {
            this.selected = val;
            this.onChange(val);
            this.onTouched();
        },
        enumerable: true,
        configurable: true
    });
    SelectSearchComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    SelectSearchComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    SelectSearchComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    return SelectSearchComponent;
}());
export { SelectSearchComponent };
