import * as tslib_1 from "tslib";
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from 'src/_util/events';
var NetworkService = /** @class */ (function () {
    function NetworkService(socketService) {
        this.socketService = socketService;
        this.networks = [];
    }
    NetworkService.prototype.init = function () {
        var _this = this;
        return this.socketService.call(Events.GET_ALL_NETWORKS, function (networks) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.networks = networks;
                return [2 /*return*/];
            });
        }); });
    };
    NetworkService.prototype.getMyNetwork = function () {
        return this.socketService.call(Events.GET_MY_NETWORK, undefined, {});
    };
    NetworkService.prototype.editNetworkScreen = function (screenId, options) {
        return this.socketService.call(Events.EDIT_SCREEN, undefined, tslib_1.__assign({ screenId: screenId }, options));
    };
    NetworkService.prototype.getScreensFromNetwork = function (networkId) {
        return this.socketService.call(Events.GET_SCREENS_FROM_NETWORK, undefined, { networkId: networkId });
    };
    NetworkService.prototype.approveAdForNetwork = function (networkId, options) {
        var _this = this;
        return this.socketService.call(Events.APPROVE_AD_FOR_NETWORK, function (network) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingNetwork;
            return tslib_1.__generator(this, function (_a) {
                existingNetwork = this.getNetworkById(networkId);
                Object.assign(existingNetwork, network);
                return [2 /*return*/];
            });
        }); }, tslib_1.__assign({ networkId: networkId }, options));
    };
    NetworkService.prototype.rejectAdForNetwork = function (networkId, options) {
        var _this = this;
        return this.socketService.call(Events.REJECT_AD_FOR_NETWORK, function (network) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingNetwork;
            return tslib_1.__generator(this, function (_a) {
                existingNetwork = this.getNetworkById(networkId);
                Object.assign(existingNetwork, network);
                return [2 /*return*/];
            });
        }); }, tslib_1.__assign({ networkId: networkId }, options));
    };
    NetworkService.prototype.editNetwork = function (networkId, options) {
        var _this = this;
        return this.socketService.call(Events.EDIT_NETWORK, function (network) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingNetwork;
            return tslib_1.__generator(this, function (_a) {
                existingNetwork = this.getNetworkById(networkId);
                Object.assign(existingNetwork, network);
                return [2 /*return*/];
            });
        }); }, tslib_1.__assign({ networkId: networkId }, options));
    };
    NetworkService.prototype.getNetworks = function () {
        return this.networks;
    };
    NetworkService.prototype.getNetworkById = function (id) {
        return this.networks.find(function (network) { return network._id === id; });
    };
    return NetworkService;
}());
export { NetworkService };
