import { NgPipesModule } from 'angular-pipes';
import { SuiModule } from 'ng2-semantic-ui';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CookieService } from 'ngx-cookie-service';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { ScheduleConfigurationComponent } from '@citadel/common-frontend/_components/schedule-configuration/schedule-configuration.component';
import { SegmentComponent } from '@citadel/common-frontend/_components/segment/segment.component';
import { TableComponent } from '@citadel/common-frontend/_components/table/table.component';
import { SelectSearchComponent } from '@citadel/common-frontend/_components/select-search/select-search.component';

const COMPONENTS = [HeaderComponent, TableComponent, SelectSearchComponent, ScheduleConfigurationComponent, SegmentComponent];
@NgModule({
	imports: [FormsModule, ReactiveFormsModule, RouterModule, CommonModule, ContextMenuModule, SuiModule, NgPipesModule],
	providers: [CookieService],
	declarations: [...COMPONENTS],
	exports: [...COMPONENTS],
	entryComponents: [...COMPONENTS],
})
export class ComponentModule { }
