import * as tslib_1 from "tslib";
export function runCollectionFilter(collection, filter, page, pageSize) {
    if (page === void 0) { page = 0; }
    if (pageSize === void 0) { pageSize = 10e9; }
    var hasValuesToFilter = !!Object.keys(filter).find(function (key) { return !!filter[key]; });
    var filteredCollection;
    if (hasValuesToFilter) {
        filteredCollection = collection.filter(function (resource) {
            for (var _i = 0, _a = Object.keys(filter); _i < _a.length; _i++) {
                var key = _a[_i];
                var value = filter[key];
                if (!value || !resource[key]) {
                    continue;
                }
                var trimmed = value.trim().toLowerCase();
                if (trimmed.length === 0) {
                    continue;
                }
                var contains = resource[key].toLowerCase().indexOf(trimmed) !== -1;
                if (!contains) {
                    return false;
                }
            }
            return true;
        });
    }
    else {
        filteredCollection = collection;
    }
    var originalFilteredLength = filteredCollection.length;
    var startIndex = page * pageSize;
    filteredCollection = filteredCollection.slice(startIndex, startIndex + pageSize);
    return {
        data: filteredCollection,
        totalAmount: originalFilteredLength,
    };
}
export function createClampInterval(min, max, points, offset) {
    if (offset === void 0) { offset = 0; }
    var interval = [];
    var range = max - min;
    for (var _i = 0, points_1 = points; _i < points_1.length; _i++) {
        var point = points_1[_i];
        interval.push(min + (range * point));
    }
    return {
        offset: offset,
        values: interval,
        points: points
    };
}
export function clampOnInterval(interval, n, clamp) {
    var values = interval.values, points = interval.points, offset = interval.offset;
    for (var i = 0; i < values.length; i++) {
        var value = values[i];
        var point = points[i];
        var difference = Math.abs((n + offset) - value);
        if (difference < clamp) {
            return [value - offset, point];
        }
    }
    return [n, undefined];
}
export function checkForClamps(intervals, n, clamp) {
    for (var _i = 0, intervals_1 = intervals; _i < intervals_1.length; _i++) {
        var interval = intervals_1[_i];
        var _a = clampOnInterval(interval, n, clamp), clampedValue = _a[0], clampedPoint = _a[1];
        if (clampedPoint !== undefined) {
            return [clampedValue, clampedPoint];
        }
    }
    return [n, undefined];
}
export function roundToNearest(value, scale) {
    return Math.round(value / scale) * scale;
}
export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}
export function sanitizeSeriesDates(data) {
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var type = data_1[_i];
        var series = type.series;
        for (var _a = 0, series_1 = series; _a < series_1.length; _a++) {
            var datapoint = series_1[_a];
            datapoint.name = new Date(datapoint.name);
        }
    }
    return data;
}
export function isSharedResource(resource, myId, isAdmin) {
    if (isAdmin) {
        return false;
    }
    if (!resource) {
        return false;
    }
    if (!resource.user) {
        return false;
    }
    return resource.user !== myId;
}
export function sleep(ms) {
    // @ts-ignore
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
export function downloadLocalResourceToDisk(data, name) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var a;
        return tslib_1.__generator(this, function (_a) {
            a = document.createElement('a');
            a.download = name;
            a.href = "data:text/plain;charset=utf-8," + encodeURIComponent(data);
            document.body.appendChild(a);
            a.click();
            a.remove();
            return [2 /*return*/];
        });
    });
}
export function downloadResourceToDisk(url, name) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, downloadResourceToMemory(url).then(function (blob) {
                        var blobUrl = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.download = name;
                        a.href = blobUrl;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function downloadResourceToMemory(url) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(url, {
                        headers: new Headers({
                            Origin: location.origin,
                        }),
                        mode: 'cors',
                    })
                        .then(function (response) { return response.blob(); })
                        .then(function (blob) {
                        return blob;
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function sortByKey(array, key, direction) {
    var items = array.sort(function (a, b) {
        var aValue = a[key] || '';
        var bValue = b[key] || '';
        if (aValue < bValue) {
            return -1;
        }
        if (aValue > bValue) {
            return 1;
        }
        return 0;
    });
    if (direction === 'desc') {
        return items.reverse();
    }
    return items;
}
export function isValidDate(date) {
    return new Date(date).toString() !== 'Invalid Date';
}
export function delay(ms) {
    return new Promise(function (resolve) { return setTimeout(function () { return resolve(undefined); }, ms); });
}
export function naiveDeepCopy(target, source) {
    for (var _i = 0, _a = Object.keys(source); _i < _a.length; _i++) {
        var key = _a[_i];
        var value = source[key];
        if (typeof value === 'object') {
            // Now you're thinking with portals
            target[key] = naiveDeepCopy(tslib_1.__assign({}, target[key]), source[key]);
        }
        else {
            target[key] = value;
        }
    }
    return target;
}
export function naiveDeepEqualityCheck(target, source) {
    for (var _i = 0, _a = Object.keys(source); _i < _a.length; _i++) {
        var key = _a[_i];
        var value = source[key];
        if (typeof value === 'object') {
            if (!naiveDeepEqualityCheck(target[key], source[key])) {
                return false;
            }
        }
        else {
            if (target[key] !== source[key]) {
                console.log(key + " - " + target[key] + " is not equal to " + source[key]);
                return false;
            }
        }
    }
    return true;
}
export function flattenArray(arr, depth) {
    if (depth === void 0) { depth = Infinity; }
    return arr.reduce(function (acc, item) {
        if (Array.isArray(item) && depth > 0) {
            acc.push.apply(acc, flattenArray(item, depth - 1)); // Recursive flatten
        }
        else {
            acc.push(item);
        }
        return acc;
    }, []);
}
