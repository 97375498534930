<div class="cdm-header">
	<div class="ui masthead vertical segment">
		<h1 class="ui header">
			<div class="cdm-header__header">
				<i *ngIf="showBack" (click)="goBack()" class="arrow alternate circle left outline icon"></i>
				{{ header }}
			</div>
			<div class="sub header">
				<div>
					<p>
						{{ subHeader }}
						<span class="ui basic label" *ngIf="sideHeader">{{ sideHeader }}</span>
					</p>
				</div>
			</div>
		</h1>
	</div>

	<div class="cdm-header__sidebar">
		<div class="cdm-header__actions">
			<button class="ui primary button" app-tour="header-action" *ngFor="let action of actions" [ngClass]="{ 'disabled': action.disabled }" (click)="action.execute($event)">
				<i class="{{ action.icon }} icon"></i>
				{{ action.label }}
			</button>
		</div>
	</div>
</div>