import { SuiModule } from 'ng2-semantic-ui';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TokenComponent } from './token.component';

@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		SuiModule,
		RouterModule.forChild([
			{
				path: '',
				component: TokenComponent,
			},
		]),
	],
	declarations: [TokenComponent],
	exports: [TokenComponent],
})
export class TokenModule { }
