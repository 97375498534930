import * as tslib_1 from "tslib";
import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { SelectSearchModalComponent } from './selectSearch.modal';
var SelectSearchModal = /** @class */ (function (_super) {
    tslib_1.__extends(SelectSearchModal, _super);
    function SelectSearchModal(type, options, previousSelected, property, label, size) {
        if (size === void 0) { size = ModalSize.Normal; }
        var _this = _super.call(this, SelectSearchModalComponent, { type: type, options: options, previousSelected: previousSelected, search: { property: property, label: label } }) || this;
        _this.isClosable = false;
        _this.transitionDuration = 200;
        _this.size = size;
        return _this;
    }
    return SelectSearchModal;
}(ComponentModalConfig));
export { SelectSearchModal };
