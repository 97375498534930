import * as tslib_1 from "tslib";
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from 'src/_util/events';
var NetworkGroupService = /** @class */ (function () {
    function NetworkGroupService(socketService) {
        this.socketService = socketService;
        this.networkGroups = [];
    }
    NetworkGroupService.prototype.init = function () {
        var _this = this;
        return this.socketService.call(Events.GET_ALL_NETWORK_GROUPS, function (networkGroups) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.networkGroups = networkGroups;
                return [2 /*return*/];
            });
        }); });
    };
    NetworkGroupService.prototype.createNetworkGroup = function (networkId, options) {
        var _this = this;
        return this.socketService.call(Events.CREATE_NETWORK_GROUP, function (networkGroup) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.networkGroups.push(networkGroup);
                return [2 /*return*/];
            });
        }); }, tslib_1.__assign({ networkId: networkId }, options));
    };
    NetworkGroupService.prototype.editNetworkGroup = function (networkGroupId, options) {
        var _this = this;
        return this.socketService.call(Events.EDIT_NETWORK_GROUP, function (networkGroup) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingNetworkGroup;
            return tslib_1.__generator(this, function (_a) {
                existingNetworkGroup = this.getNetworkGroupById(networkGroupId);
                Object.assign(existingNetworkGroup, networkGroup);
                return [2 /*return*/];
            });
        }); }, tslib_1.__assign({ networkGroupId: networkGroupId }, options));
    };
    NetworkGroupService.prototype.deleteNetworkGroup = function (networkGroupId) {
        var _this = this;
        return this.socketService.call(Events.DELETE_NETWORK_GROUP, function (networkGroup) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var existingIndex;
            return tslib_1.__generator(this, function (_a) {
                existingIndex = this.networkGroups.findIndex(function (existingNetworkGroup) { return existingNetworkGroup._id === networkGroup._id; });
                if (existingIndex === -1) {
                    return [2 /*return*/];
                }
                this.networkGroups.splice(existingIndex, 1);
                return [2 /*return*/];
            });
        }); }, { networkGroupId: networkGroupId });
    };
    NetworkGroupService.prototype.getNetworkGroupsForNetwork = function (networkId) {
        return this.socketService.call(Events.GET_NETWORK_GROUPS_FOR_NETWORK, undefined, { networkId: networkId });
    };
    NetworkGroupService.prototype.getNetworkGroups = function () {
        return this.networkGroups;
    };
    NetworkGroupService.prototype.getNetworkGroupById = function (id) {
        return this.networkGroups.find(function (networkGroup) { return networkGroup._id === id; });
    };
    return NetworkGroupService;
}());
export { NetworkGroupService };
