import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isValidDate } from '@citadel/common-frontend/_util/functions';
import { IScheduledSlotConfiguration } from '../../_interfaces/IScheduledSlotConfiguration';

@Component({
	selector: 'app-schedule-configuration',
	templateUrl: './schedule-configuration.component.html',
	styleUrls: ['./schedule-configuration.component.scss'],
})
export class ScheduleConfigurationComponent implements AfterViewInit {
	@Input()
	public configuration: IScheduledSlotConfiguration;

	@Input()
	public showFrequency: boolean = true;
	
	public days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

	public frequencySelectionOptions: any[] = [
		{
			label: 'Minutes',
			value: 'minutes',
		},
		{
			label: 'Hours',
			value: 'hours',
		},
	];

	public dateNow = new Date();

	public scheduledSlot: FormGroup;

	private hasLoaded: boolean = false;

	constructor(private fb: FormBuilder) { }

	private initializeForm(): void {
		this.scheduledSlot = this.fb.group({
			frequency: this.fb.group({
				type: ['standard', Validators.compose([Validators.required])],
				interval: this.fb.group({
					value: [5],
					period: ['minutes'],
				}),
			}),
			weekday: this.fb.group({
				enabled: [false, Validators.compose([Validators.required])],
				selected: this.fb.group({
					monday: [false],
					tuesday: [false],
					wednesday: [false],
					thursday: [false],
					friday: [false],
					saturday: [false],
					sunday: [false],
				}),
			}),
			dates: this.fb.group({
				enabled: [false, Validators.compose([Validators.required])],
				range: this.fb.group({
					start: [],
					end: [],
				}),
			}),
			times: this.fb.group({
				enabled: [false, Validators.compose([Validators.required])],
				range: this.fb.array([]),
			}),
		});

		this.scheduledSlot.valueChanges.subscribe(() => {
			if (!this.hasLoaded) {
				return;
			}

			Object.assign(this.configuration, this.getConfiguration());
		});
	}

	ngAfterViewInit() {
		this.hasLoaded = false;
		
		this.initializeForm();

		if (this.configuration) {
			if (this.configuration.dates) {
				if (this.configuration.dates.range.start && isValidDate(this.configuration.dates.range.start)) {
					this.configuration.dates.range.start = new Date(this.configuration.dates.range.start);
				}

				if (this.configuration.dates.range.end && isValidDate(this.configuration.dates.range.end)) {
					this.configuration.dates.range.end = new Date(this.configuration.dates.range.end);
				}
			}

			this.scheduledSlot.patchValue(this.configuration);

			if (this.configuration.times) {
				if (this.configuration.times.range.length > 0) {
					this.configuration.times.range.forEach((item) => {
						this.timeRanges.push(
							this.fb.group({
								start: isValidDate(item.start) ? new Date(item.start) : '',
								end: isValidDate(item.end) ? new Date(item.end) : '',
							})
						);
					});
				} else {
					this.addTimeRange();
				}
			} else {
				this.addTimeRange();
			}
		}

		this.hasLoaded = true;
	}

	get timeRanges(): FormArray {
		return this.scheduledSlot.get('times.range') as FormArray;
	}

	addTimeRange() {
		this.timeRanges.push(
			this.fb.group({
				start: this.fb.control(''),
				end: this.fb.control(''),
			})
		);
	}

	deleteTime(index) {
		this.timeRanges.removeAt(index);
	}

	rangePicker(type, index) {

	}

	public getConfiguration(): any {
		if (!this.scheduledSlot) {
			return null;
		}
		return this.scheduledSlot.value;
	}

	public canSave() {
		const configuration = this.getConfiguration();
		if (!configuration) {
			return false;
		}

		let canSave = true;
		if (configuration.dates.enabled) {
			if (!configuration.dates.range.start || !isValidDate(configuration.dates.range.start) || !configuration.dates.range.end || !isValidDate(configuration.dates.range.end)) {
				return false;
			}
		}
		if (configuration.times.enabled) {
			if (configuration.times.range.length) {
				configuration.times.range.forEach((item) => {
					if (!isValidDate(item.start) || !isValidDate(item.end)) {
						canSave = false;
						return;
					}
				});
				return canSave;
			}
		} else {
			return true;
		}
		return canSave;
	}
}
