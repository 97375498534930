<h4 class="ui inverted top attached header cdm-segment__header" (click)="toggleCollapsed()">
  <i *ngIf="icon" class="{{ icon }} icon cdm-segment__header-icon"></i>
  {{ header }}
  <div class="cdm-segment__actions">
    <ng-container *ngFor="let dropdown of dropdowns">
      <sui-select class="inverted selection" (click)="$event.stopPropagation()"
        [(ngModel)]="dropdown.value"
        [options]="dropdown.options"
        labelField="label"
        valueField="value"
        (ngModelChange)="dropdown.onChange($event)"
        #segmentSelect>
        <sui-select-option *ngFor="let option of segmentSelect.filteredOptions"
          [value]="option">
        </sui-select-option>
      </sui-select>
    </ng-container>
    <ng-container *ngFor="let action of actions">
      <button class="ui compact button icon tiny inverted"
        (click)="$event.stopPropagation(); action.execute();">
        <i *ngIf="action.icon" class="{{ action.icon }} icon"></i>
        {{ action.label }}
      </button>
    </ng-container>
  </div>
  <i class="cdm-segment__collapser angle down icon" [ngClass]="{ 'cdm-segment__collapser--hidden': collapsed }"
    (click)="$event.stopPropagation(); toggleCollapsed();"></i>
</h4>
<div class="ui attached segment stacked cdm-segment__content"
  [ngClass]="{ 'cdm-segment__content--collapsed': collapsed, 'cdm-segment__content--fitted': !padding, 'stacked': collapsed }"
  [suiCollapse]="collapsed">
  <ng-content></ng-content>

  <div class="ui active inverted dimmer cdm-segment__loader" *ngIf="loading">
    <div class="ui loader"></div>
  </div>
</div>