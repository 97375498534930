import { Observable } from 'rxjs';
import Events from 'src/_util/events';

import { Injectable } from '@angular/core';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';

@Injectable()
export class StatisticService {
	constructor(private socket: SocketService) { }

	public init(): Observable<any> {
		return new Observable((observer) => {
			observer.next();
			observer.complete();
		});
	}

	public getReportServerMeta(): Observable<any> {
		return this.socket.call(Events.GET_REPORT_SERVER_METADATA, null);
	}

	public generateReport(filter: any, exportConfig: any): Observable<any> {
		return this.socket.call(Events.GENERATE_REPORT, null, filter, exportConfig);
	}

	public getReportProgress(url: string): Observable<any> {
		return this.socket.call(Events.GET_PROGRESS, null, url);
	}
}
