import { AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { isValidDate } from '@citadel/common-frontend/_util/functions';
var ScheduleConfigurationComponent = /** @class */ (function () {
    function ScheduleConfigurationComponent(fb) {
        this.fb = fb;
        this.showFrequency = true;
        this.days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        this.frequencySelectionOptions = [
            {
                label: 'Minutes',
                value: 'minutes',
            },
            {
                label: 'Hours',
                value: 'hours',
            },
        ];
        this.dateNow = new Date();
        this.hasLoaded = false;
    }
    ScheduleConfigurationComponent.prototype.initializeForm = function () {
        var _this = this;
        this.scheduledSlot = this.fb.group({
            frequency: this.fb.group({
                type: ['standard', Validators.compose([Validators.required])],
                interval: this.fb.group({
                    value: [5],
                    period: ['minutes'],
                }),
            }),
            weekday: this.fb.group({
                enabled: [false, Validators.compose([Validators.required])],
                selected: this.fb.group({
                    monday: [false],
                    tuesday: [false],
                    wednesday: [false],
                    thursday: [false],
                    friday: [false],
                    saturday: [false],
                    sunday: [false],
                }),
            }),
            dates: this.fb.group({
                enabled: [false, Validators.compose([Validators.required])],
                range: this.fb.group({
                    start: [],
                    end: [],
                }),
            }),
            times: this.fb.group({
                enabled: [false, Validators.compose([Validators.required])],
                range: this.fb.array([]),
            }),
        });
        this.scheduledSlot.valueChanges.subscribe(function () {
            if (!_this.hasLoaded) {
                return;
            }
            Object.assign(_this.configuration, _this.getConfiguration());
        });
    };
    ScheduleConfigurationComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.hasLoaded = false;
        this.initializeForm();
        if (this.configuration) {
            if (this.configuration.dates) {
                if (this.configuration.dates.range.start && isValidDate(this.configuration.dates.range.start)) {
                    this.configuration.dates.range.start = new Date(this.configuration.dates.range.start);
                }
                if (this.configuration.dates.range.end && isValidDate(this.configuration.dates.range.end)) {
                    this.configuration.dates.range.end = new Date(this.configuration.dates.range.end);
                }
            }
            this.scheduledSlot.patchValue(this.configuration);
            if (this.configuration.times) {
                if (this.configuration.times.range.length > 0) {
                    this.configuration.times.range.forEach(function (item) {
                        _this.timeRanges.push(_this.fb.group({
                            start: isValidDate(item.start) ? new Date(item.start) : '',
                            end: isValidDate(item.end) ? new Date(item.end) : '',
                        }));
                    });
                }
                else {
                    this.addTimeRange();
                }
            }
            else {
                this.addTimeRange();
            }
        }
        this.hasLoaded = true;
    };
    Object.defineProperty(ScheduleConfigurationComponent.prototype, "timeRanges", {
        get: function () {
            return this.scheduledSlot.get('times.range');
        },
        enumerable: true,
        configurable: true
    });
    ScheduleConfigurationComponent.prototype.addTimeRange = function () {
        this.timeRanges.push(this.fb.group({
            start: this.fb.control(''),
            end: this.fb.control(''),
        }));
    };
    ScheduleConfigurationComponent.prototype.deleteTime = function (index) {
        this.timeRanges.removeAt(index);
    };
    ScheduleConfigurationComponent.prototype.rangePicker = function (type, index) {
    };
    ScheduleConfigurationComponent.prototype.getConfiguration = function () {
        if (!this.scheduledSlot) {
            return null;
        }
        return this.scheduledSlot.value;
    };
    ScheduleConfigurationComponent.prototype.canSave = function () {
        var configuration = this.getConfiguration();
        if (!configuration) {
            return false;
        }
        var canSave = true;
        if (configuration.dates.enabled) {
            if (!configuration.dates.range.start || !isValidDate(configuration.dates.range.start) || !configuration.dates.range.end || !isValidDate(configuration.dates.range.end)) {
                return false;
            }
        }
        if (configuration.times.enabled) {
            if (configuration.times.range.length) {
                configuration.times.range.forEach(function (item) {
                    if (!isValidDate(item.start) || !isValidDate(item.end)) {
                        canSave = false;
                        return;
                    }
                });
                return canSave;
            }
        }
        else {
            return true;
        }
        return canSave;
    };
    return ScheduleConfigurationComponent;
}());
export { ScheduleConfigurationComponent };
