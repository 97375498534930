import * as tslib_1 from "tslib";
import { runCollectionFilter } from '../../_util/functions';
import { Component, EventEmitter, OnInit } from '@angular/core';
var TableComponent = /** @class */ (function () {
    function TableComponent() {
        this.tableId = '';
        this.items = [];
        this.columns = [];
        this.pageSize = 10e9;
        this.actions = [];
        this.showId = false;
        this.onRowSelect = new EventEmitter();
        this.onView = new EventEmitter();
        this.onDelete = new EventEmitter();
        this.onCheckChange = new EventEmitter();
        this.visibleItems = [];
        this.filter = {};
        this.page = 0;
        this.visiblePages = [];
        this.showNavigateBackEllipsis = false;
        this.showNavigateForwardEllipsis = false;
        this.pageCountToShow = 5;
    }
    TableComponent.prototype.ngOnInit = function () {
        this.viewPage(0);
    };
    TableComponent.prototype.refresh = function () {
        this.viewPage(0);
    };
    TableComponent.prototype.toggleIDs = function () {
        this.showId = !this.showId;
    };
    TableComponent.prototype.getHeaderFillerColumnCount = function () {
        var onViewColumns = this.onView.observers.length > 0 ? 1 : 0;
        var actionsColumns = this.actions.length;
        var deleteColumns = this.onDelete.observers.length > 0 ? 1 : 0;
        var offset = 1;
        return (onViewColumns + actionsColumns + deleteColumns) - offset;
    };
    TableComponent.prototype.getRowClasses = function (item) {
        var classes = {};
        classes['cdm-table__selectable-row'] = (this.onRowSelect.observers.length > 0);
        var status = this.checkItemStatus(item);
        switch (status) {
            case 'positive':
                classes['left'] = true;
                classes['green'] = true;
                classes['marked'] = true;
                break;
            case 'warning':
                classes['left'] = true;
                classes['orange'] = true;
                classes['marked'] = true;
                break;
            case 'negative':
                classes['left'] = true;
                classes['red'] = true;
                classes['marked'] = true;
                break;
            case 'info':
                classes['left'] = true;
                classes['primary'] = true;
                classes['marked'] = true;
                break;
            case 'notice':
                classes['left'] = true;
                classes['secondary'] = true;
                classes['marked'] = true;
                break;
        }
        return classes;
    };
    TableComponent.prototype.checkItemStatus = function (item) {
        if (!this.getStatus) {
            return null;
        }
        return this.getStatus(item);
    };
    TableComponent.prototype.getMaxPageCount = function () {
        return Math.ceil(this.visibleItemsLength / this.pageSize);
    };
    TableComponent.prototype.nextPage = function () {
        if (this.page + 1 === this.getMaxPageCount()) {
            return;
        }
        this.viewPage(++this.page);
    };
    TableComponent.prototype.previousPage = function () {
        if (this.page === 0) {
            return;
        }
        this.viewPage(--this.page);
    };
    TableComponent.prototype.onForwardEllipsis = function () {
        var pageToNavigateTo = this.page + Math.ceil(this.pageCountToShow);
        if (pageToNavigateTo > this.getMaxPageCount()) {
            return this.viewPage(this.getMaxPageCount() - 1);
        }
        this.viewPage(pageToNavigateTo);
    };
    TableComponent.prototype.onBackEllipsis = function () {
        var pageToNavigateTo = this.page - Math.ceil(this.pageCountToShow);
        if (pageToNavigateTo < 0) {
            return this.viewPage(0);
        }
        this.viewPage(pageToNavigateTo);
    };
    TableComponent.prototype.viewPage = function (page) {
        this.page = page;
        this.runFilter();
        this.showNavigateBackEllipsis = true;
        this.showNavigateForwardEllipsis = true;
        var lowerLimit = this.page - Math.floor(this.pageCountToShow / 2);
        var upperLimit = this.page + Math.ceil(this.pageCountToShow / 2);
        if (lowerLimit <= 0) {
            upperLimit = Math.min(this.getMaxPageCount(), upperLimit - lowerLimit);
            lowerLimit = 0;
        }
        if (upperLimit >= this.getMaxPageCount()) {
            lowerLimit = Math.max(0, this.getMaxPageCount() - this.pageCountToShow);
            upperLimit = this.getMaxPageCount();
        }
        this.visiblePages = [];
        for (var limit = lowerLimit; limit < upperLimit; limit++) {
            this.visiblePages.push(limit);
        }
        if (this.visiblePages.length === 0) {
            this.showNavigateBackEllipsis = false;
            this.showNavigateForwardEllipsis = false;
        }
        if (this.visiblePages[0] === 0) {
            this.showNavigateBackEllipsis = false;
        }
        if (this.visiblePages[this.visiblePages.length - 1] + 1 === this.getMaxPageCount()) {
            this.showNavigateForwardEllipsis = false;
        }
    };
    TableComponent.prototype.onSearchChange = function () {
        this.viewPage(0);
    };
    TableComponent.prototype.onInternalItemCheckChange = function ($event, item) {
        if (this.onCheckChange) {
            this.onCheckChange.emit({ value: !!$event, item: item });
        }
    };
    TableComponent.prototype.runFilter = function () {
        var _a = runCollectionFilter(this.items, this.filter, this.page, this.pageSize), data = _a.data, totalAmount = _a.totalAmount;
        this.visibleItems = data;
        this.visibleItemsLength = totalAmount;
    };
    TableComponent.prototype.getEffectiveColumnCount = function () {
        var columnCount = this.columns.length;
        if (this.onView.observers.length > 0) {
            columnCount++;
        }
        if (this.onDelete.observers.length > 0) {
            columnCount++;
        }
        if (this.onCheckChange.observers.length > 0) {
            columnCount++;
        }
        if (this.showId) {
            columnCount++;
        }
        columnCount += this.actions.length;
        return columnCount;
    };
    TableComponent.prototype.sanitizeCellValue = function (value) {
        if (value === null || value === undefined || value === 'undefined' || value === 'null') {
            return 'N/A';
        }
        return value.toString();
    };
    TableComponent.prototype.clearFilter = function () {
        this.filter = {};
        this.viewPage(0);
    };
    TableComponent.prototype.onItemClicked = function (item) {
        if (this.onRowSelect) {
            this.onRowSelect.emit(item);
        }
    };
    TableComponent.prototype.viewItem = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.onView) {
                    this.onView.emit(item);
                }
                return [2 /*return*/];
            });
        });
    };
    TableComponent.prototype.deleteItem = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.onDelete) {
                    this.onDelete.emit(item);
                }
                return [2 /*return*/];
            });
        });
    };
    TableComponent.prototype.isInternalItemSelected = function (item) {
        if (this.isItemSelected) {
            return this.isItemSelected(item, this.parent);
        }
        else {
            return false;
        }
    };
    TableComponent.prototype.isSharedResource = function (item) {
        return false;
    };
    return TableComponent;
}());
export { TableComponent };
